import {createApp} from 'vue';
import App from './App.vue';
import store from './store';

/* eslint-disable no-unused-vars */
import "@/assets/fonts.css";
import "@/assets/tooltip.css"; // global styles
import "@/assets/fade.css"; // global styles
/* eslint-enable no-unused-vars */

createApp(App).use(store).mount('#app');
