import {createStore} from 'vuex';
import axios from 'axios'
import * as utils from './utils.js';

const getDefaultState = () => {
    return {
        config: null,
        program: {
            degree: "",
            major: "",
            fpso: "",
            standardPeriodOfStudy: 0,
            maxPeriodOfStudy: 0,
            thesisModuleId: "",
            germanRequired: true,
            englishRequired: true,
            blocks: [],
            structures: [],
            modules: [],
            offeredSemesters: {},
            moduleFrequencies: {}
        },
        preferences: {
            id: "",
            degree: "",
            major: "",
            startSemester: "",
            fpso: "",
            structures: {},
            currentSemester: null,
            passedModules: [],
            undesiredModules: [],
            desiredModules: [],
            desiredInSemModules: {},
            en: true,
            de: true,
            minEctsPerSemester: 0,
            maxEctsPerSemester: 32,
            minExamsPerSemester: 0,
            maxExamsPerSemester: 6,
            ignoreTimetable: false,
            semesterPreferences: {},
        },
        schedule: {},
        offeredSemesters: {},
        blockCredits: {},
        nextSemesters: [],
        violatedConstraints: [],
        isLoadingSchedule: false,
        isLoadingProgram: false,
        hasSolution: false,
        programLoaded: false,
        currentModal: null,
        currentModalSemester: null,
        promptedPassedModules: false,
        errorOccurred: false,
        history: [], // Stack to store the history of state changes
    }
}

const getDefaultSemesterPreference = () => {
    return {
        default: true,
        minEcts: 0,
        maxEcts: 32,
        minExams: 0,
        maxExams: 6,
        freeMon: false,
        freeTue: false,
        freeWed: false,
        freeThu: false,
        freeFri: false,
    }
}

const getDefaultValue = (property, subproperty = null) => {
    if (subproperty) {
        return getDefaultState()[property][subproperty];
    }
    return getDefaultState()[property];
}

export default createStore({
    state: getDefaultState,
    getters: {
        reqPrefsSet(state, getters) {
            const basicConditionsMet = getters.programPrefsSet
                && state.preferences.startSemester.length !== 0;

            const allStructuresHavePreference = state.program.structures.every(structure => {
                const structurePref = state.preferences.structures[structure.id];
                return structurePref !== undefined && structurePref !== "";
            });

            return basicConditionsMet && allStructuresHavePreference;
        },
        programPrefsSet(state) {
            return state.preferences.degree.length !== 0
                && state.preferences.major.length !== 0
                && state.preferences.fpso.length !== 0;
        },
        requiredModules(state) {
            const required = state.program.blocks.filter(block => block.allRequired)
                .map(block => block.modules)
                .flat();

            let strucRequired = [];

            state.program.structures.forEach(struc => {
                const selectedElementId = state.preferences.structures?.[struc.id];
                const selectedElement = struc.elements.find(element => element.id === selectedElementId);

                if (selectedElement) {
                    selectedElement.blocks.forEach(block => {
                        if (block.allRequired) {
                            strucRequired = [...strucRequired, ...block.modules];
                        }
                    });
                }
            });
            return [...required, ...strucRequired];
        },
        desiredModules(state) {
            return state.preferences.desiredModules;
        },
        mockModules(state) {
            const mock = state.program.blocks
                .map(block => block.modules)
                .flat()
                .filter(module => module.includes('mock'));  // get modules with 'mock' in their id

            return [...mock];
        },
        addedPassedModules(state) {
            return state.preferences.passedModules.length !== 0 || state.promptedPassedModules;
        },
        prefChangeDisabled(state) {
            return state.isLoadingSchedule;
        },
        totalECTS(state) {
            return Object.values(state.schedule)
                .map(item => item.ects)
                .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
        }
    },
    mutations: {
        SET_CONFIG(state, config) {
            state.config = config;
        },
        RESET_STATE(state) {
            Object.assign(state, getDefaultState());
        },
        RESET_PROPERTY(state, property) {
            state[property] = getDefaultValue(property);
            if (property === "preferences" && state.programLoaded) {
                for (let i = 1; i <= state.program.maxPeriodOfStudy; i++) {
                    this.commit("ADD_SEMESTER_PREFERENCE", {
                        semester: i,
                        semesterPreference: getDefaultSemesterPreference()
                    });
                }
            }
        },
        RESET_SUBPROPERTY(state, {property, subproperty}) {
            state[property][subproperty] = getDefaultValue(property, subproperty);
        },
        SET_PROGRAM(state, program) {
            state.program = program;
        },
        SET_PREFERENCE(state, preference) {
            state.preferences[preference.key] = preference.value;
        },
        SET_STRUCTURE_OPTION(state, {structureId, option}) {
            state.preferences.structures = {...state.preferences.structures, [structureId]: option};
        },
        ADD_PASSED_MODULE(state, passedModule) {
            if (!state.preferences.passedModules.includes(passedModule)) {
                // make sure to keep module in only one choice-list
                this.commit("REMOVE_UNDESIRED_MODULE", {undesiredModule: passedModule, removeSameNameMocks: false});
                this.commit("REMOVE_DESIRED_MODULE", passedModule);
                this.commit("REMOVE_DESIRED_IN_SEM_MODULE", passedModule);
                state.preferences.passedModules.push(passedModule);
            }
        },
        REMOVE_PASSED_MODULE(state, passedModule) {
            // Remove module from passed modules
            state.preferences.passedModules = state.preferences.passedModules.filter(num => num !== passedModule)

            // Check if there's any module with the same name in undesiredModules
            const module = state.program.modules.find(module => module.id === passedModule);
            if (!module || !module.mock) return;
            const sameMockUndesired = state.program.modules.find(module => {
                return module.mock && module.name === module.name && state.preferences.undesiredModules.includes(module.id);
            });

            // Add module to undesiredModules, if other mock of same name is
            if (sameMockUndesired !== undefined) {
                this.commit("ADD_UNDESIRED_MODULE", passedModule);
            }
        },
        ADD_UNDESIRED_MODULE(state, undesiredModule) {
            const foundModule = state.program.modules.find(module => module.id === undesiredModule);
            let modulesToAdd = [];

            // Gather modules to add
            if (foundModule && foundModule.mock && !foundModule.id.includes("UEG")) {
                modulesToAdd = state.program.modules
                    .filter(module => module.name === foundModule.name && module.mock)
                    .map(module => module.id);
            } else {
                modulesToAdd = [undesiredModule];
            }

            // Create a new reference for undesiredModules to ensure reactivity
            const currentUndesiredModules = [...state.preferences.undesiredModules];

            modulesToAdd.forEach(moduleId => {
                if (!currentUndesiredModules.includes(moduleId) &&
                    !state.preferences.passedModules.includes(moduleId)) {
                    this.commit("REMOVE_DESIRED_MODULE", moduleId);
                    this.commit("REMOVE_DESIRED_IN_SEM_MODULE", moduleId);
                    currentUndesiredModules.push(moduleId); // Add to temporary array
                }
            });

            // Update state with new reference
            state.preferences.undesiredModules = currentUndesiredModules;
        },
        REMOVE_UNDESIRED_MODULE(state, payload) {
            let undesiredModule, removeSameNameMocks;

            // Check if payload is an object (new usage)
            if (typeof payload === 'object' && payload !== null) {
                undesiredModule = payload.undesiredModule;
                removeSameNameMocks = payload.removeSameNameMocks; // This is optional
            } else {
                // For backward compatibility (existing usage)
                undesiredModule = payload;
                removeSameNameMocks = true;
            }

            const foundModule = state.program.modules.find(module => module.id === undesiredModule);
            let modulesToRemove = new Set();

            // last condition is a hack to avoid no plan if one UEG module is undesired
            if (removeSameNameMocks && foundModule && foundModule.mock && !foundModule.id.includes("UEG")) {
                // If the module is a mock, gather all modules with the same name
                state.program.modules
                    .filter(module => module.name === foundModule.name && module.mock)
                    .forEach(module => modulesToRemove.add(module.id));
            } else {
                // For non-mock modules, remove only the specified module
                modulesToRemove.add(undesiredModule);
            }

            // Remove modules from undesiredModules
            state.preferences.undesiredModules = state.preferences.undesiredModules
                .filter(moduleId => !modulesToRemove.has(moduleId));
        },
        ADD_DESIRED_MODULE(state, desiredModule) {
            state.preferences.desiredModules.push(desiredModule);
        },
        REMOVE_DESIRED_MODULE(state, desiredModule) {
            state.preferences.desiredModules = state.preferences.desiredModules.filter(item => item !== desiredModule);
        },
        ADD_DESIRED_IN_SEM_MODULE(state, {module, semester}) {

            if (!state.preferences.passedModules.includes(module)) {
                // make sure to keep module in only one choice-list
                this.commit("REMOVE_PASSED_MODULE", module);
                this.commit("REMOVE_UNDESIRED_MODULE", module);
                this.commit("REMOVE_DESIRED_MODULE", module);
                // avoid double assignment
                this.commit("REMOVE_DESIRED_IN_SEM_MODULE", module);
                state.preferences.desiredInSemModules[module] = semester;  
            }
        },
        REMOVE_DESIRED_IN_SEM_MODULE(state, desiredInSemModule) {
            delete state.preferences.desiredInSemModules[desiredInSemModule];
        },
        ADD_SEMESTER_PREFERENCE(state, {semester, semesterPreference}) {
            state.preferences.semesterPreferences[semester] = semesterPreference;
        },
        UPDATE_SEMESTER_PREFERENCE(state, {semester, key, value}) {
            if (semester !== undefined && key !== undefined && value !== undefined) {
                state.preferences.semesterPreferences[semester][key] = value;
            }
        },
        UPDATE_SCHEDULE(state, schedule) {
            state.schedule = schedule;
        },
        UPDATE_OFFERED_SEMESTERS(state, offeredSemesters) {
            state.offeredSemesters = offeredSemesters;
        },
        UPDATE_BLOCK_CREDITS(state, blockCredits) {
        state.blockCredits = blockCredits;
        },
        SET_SCHEDULE_LOADING_STATUS(state, status) {
            state.isLoadingSchedule = status;
        },
        SET_PROGRAM_LOADING_STATUS(state, status) {
            state.isLoadingProgram = status;
        },
        SET_SOLUTION_STATUS(state, status) {
            state.hasSolution = status;
        },
        SET_PROGRAM_LOAD_STATUS(state, status) {
            state.programLoaded = status;
        },
        SET_ERROR_STATUS(state, status) {
            state.errorOccurred = status;
        },
        SET_VIOLATED_CONSTRAINTS(state, constraints) {
            state.violatedConstraints = constraints;
        },
        OPEN_MODAL(state, payload) {
            // Check if payload is an object
            if (typeof payload === 'object' && payload !== null) {
                // For semester-dependent modals
                state.currentModal = payload.modal;
                state.currentModalSemester = payload.semester;
            } else {
                // For semester-independent modals
                state.currentModal = payload;
            }
        },
        CLOSE_MODAL(state) {
            state.currentModal = null;
            state.currentModalSemester = null;
        },
        RESET_PROGRAM(state) {
            state.programLoaded = false;
            state.program = getDefaultValue("program");
        },
        RESET_ALL_PREFERENCES_BUT_BASE(state) {
            state.promptedPassedModules = false;
            let preferences = getDefaultState().preferences;
            preferences.degree = state.preferences.degree;
            preferences.major = state.preferences.major;
            preferences.startSemester = state.preferences.startSemester;
            preferences.fpso = state.preferences.fpso;
            state.preferences = preferences;
        },
        RESET_SCHEDULE(state) {
            state.hasSolution = false;
            state.schedule = getDefaultValue("schedule");
            state.offeredSemesters = getDefaultValue("offeredSemesters");
        },
        SET_PROMPTED_PASSED_MODULES(state) {
            state.promptedPassedModules = true;
        },
        SET_NEXT_SEMESTERS(state, semesters) {
            state.nextSemesters = semesters;
        },
        SET_MAX_ECTS_PER_SEMESTER(state, maxEcts) {
            state.preferences.maxEctsPerSemester = maxEcts;
        },
        SAVE_STATE(state) {
            if (!state.history) {
                state.history = [];
            }
            // Save a deep copy of the current state
            state.history.push(JSON.parse(JSON.stringify(state)));
        },
        UNDO_CHANGE(state) {
            if (state.history && state.history.length > 0) {
                const previousState = state.history.pop();
                Object.keys(previousState).forEach((key) => {
                    state[key] = JSON.parse(JSON.stringify(previousState[key]));
                });
            }
            state.currentModal = null;
            state.currentModalSemester = null; 
        },
    },
    actions: {
        async loadConfig({ commit }) {
            try {
                const response = await fetch(process.env.VUE_APP_API_URL + '/api/config');
                if (!response.ok) {
                    throw new Error('Failed to fetch config');
                }
                const config = await response.json();
                commit("SET_CONFIG", config);
            } catch (error) {
                console.error("Failed to load config", error);
            }
        },
        requestSchedule(context) {
            this.commit("SET_SCHEDULE_LOADING_STATUS", true);
            context.state.preferences.currentSemester = context.state.config.currentSemester;
            axios.post(process.env.VUE_APP_API_URL + "/api/schedule", context.state.preferences)
                .then(response => {
                    context.commit("SET_ERROR_STATUS", false);
                    if (response.status === 200) {
                       if (response.data.violated_constraints && Array.isArray(response.data.violated_constraints)) {
                            // Retrieve violated constraints
                            const violatedConstraints = response.data.violated_constraints;
                            if (violatedConstraints.length > 0) {
                                context.commit("SET_SOLUTION_STATUS", false);
                                context.commit("SET_VIOLATED_CONSTRAINTS", violatedConstraints);
                            }
                        } else {
                            const { schedule, blockCredits, offeredSemesters } = response.data;
                            if (schedule) {
                                // Valid schedule found
                                context.commit("SET_SOLUTION_STATUS", true);
                                context.commit("UPDATE_SCHEDULE", schedule);
                                context.commit("UPDATE_OFFERED_SEMESTERS", offeredSemesters);
                                context.commit("UPDATE_BLOCK_CREDITS", blockCredits);
                                context.commit("SET_VIOLATED_CONSTRAINTS", []);
                            }
                        }
                    } else if (response.status === 400) {
                            context.commit("SET_SOLUTION_STATUS", false);
                            context.commit("SET_VIOLATED_CONSTRAINTS", []);
                    } else {
                        context.commit("SET_SOLUTION_STATUS", false);
                    }
                })
                .catch(error => {
                    context.commit("SET_ERROR_STATUS", true);
                    console.error(error);
                })
                .finally(() => {
                    context.commit("SET_SCHEDULE_LOADING_STATUS", false);
                });
        },
        requestProgramDetails(context) {
            this.commit("SET_PROGRAM_LOADING_STATUS", true);
            const degree = context.state.config.degrees.find(degree => degree.id === context.state.preferences.degree);
            const major = degree.departments.flatMap(department => department.majors).find(major => major.name === context.state.preferences.major);
            context.state.preferences.id = `${context.state.preferences.degree}_${major.id}_${context.state.preferences.fpso}`;
            return axios.get(process.env.VUE_APP_API_URL + "/api/program", {
                params: {
                    id: context.state.preferences.id
                }
            })
                .then(response => {
                    context.commit("SET_ERROR_STATUS", false);
                    context.commit("SET_PROGRAM", response.data);
                    // reset semesterPreferences if set from previous request
                    context.commit("RESET_SUBPROPERTY", {property: "preferences", subproperty: "semesterPreferences"});
                    // dynamically add elements for semesterPreferences for each semester
                    if (utils.isEmpty(context.state.preferences.semesterPreferences)) {
                        for (let i = 1; i <= context.state.program.maxPeriodOfStudy; i++) {
                            this.commit("ADD_SEMESTER_PREFERENCE", {
                                semester: i,
                                semesterPreference: getDefaultSemesterPreference()
                            });
                        }
                    }
                    context.commit("SET_PROGRAM_LOAD_STATUS", true);
                })
                .catch(error => {
                    context.commit("SET_ERROR_STATUS", true);
                    context.commit("RESET_PROGRAM");
                    console.error(error);
                })
                .finally(() => {
                    context.commit("SET_PROGRAM_LOADING_STATUS", false);
                });
        },
        async sendReport(context, content) {
            const message = utils.sanitizeInput(content.message);
            const payload =
                {
                    "message": message,
                    "source": process.env.VUE_APP_API_URL,
                    "state": content.consentData ? context.state : {},
                    "browser": content.consentMetaData ? utils.getBrowserInfo() : {}
                };
            try {
                const response = await axios.post(process.env.VUE_APP_API_URL + "/api/report", payload);
                return response.status >= 200 && response.status < 300;
            } catch (error) {
                console.error(error);
                return false;
            }
        },
        openModal({commit}, payload) {
            commit("OPEN_MODAL", payload);
        },
        selectStructureOption({commit}, payload) {
            commit("SET_STRUCTURE_OPTION", payload);
        },
    },
});
