<template>
  <base-modal
      :icon-modal="iconModal"
      :modal-subtitle="modalSubtitle"
      :modal-title="modalTitle"
      :text-confirm="baseTextConfirm"
      :text-discard="textDiscard"
      :closing-offered="closingOffered"
      :confirmation-disabled="confirmationDisabled"
      :discarding-disabled="!changeDetected"
      :confirmation-required="true"
      :modal-name="modalName"
      :close-on-confirm="closeOnConfirm"
      :discarding-offered="discardingOffered"
      @confirm="confirm"
  >
    <template #body>
      <div v-if="this.$props.showInput" class="modal-entry">
        <div class="input-container">
          <input ref="input" v-model="moduleInput"
                 class="wide-input"
                 placeholder="Select a module or enter name or number to search..."
                 type="text"
                 @blur="showDropdown = false"
                 @focus="showDropdown = true"/>
          <div :class="{'dropdown-shown' : showDropdown }" class="material-symbols-outlined icon-expand"
               @mousedown.prevent="toggleFocusInput">expand_more
          </div>
          <ul v-if="selectableModules.length && showDropdown" class="suggestion-dropdown">
            <!-- use mousedown instead of click event, to prevent dropdown disappearing before click event registered-->
            <li v-for="module in filterModulesByInput(selectableModules)" :key="module.id"
                :class="['suggestion-item', { 'inactive': unavailableModules.includes(module.id) }, structureColorClass(module.id)]"
                @mousedown.prevent="event => clickMarkModule(event, module)">
              <div class="module-name-bar">
                {{ module.name.length > 60 ? module.name.substring(0, 60) + '...' : module.name }}
                <span v-if="unavailableModules.includes(module.id)"
                      class="list-detail">{{ '(' + unavailableReason + ')' }}
                </span>
              </div>
              <div class="list-details">
                <div class="list-detail list-detail-num">{{ module.mock ? '' : module.number }}</div>
                <div class="list-detail list-detail-lang">{{ module.languages.sort().join('/') }}</div>
                <div class="list-detail list-detail-ects">{{ module.ects }} ECTS</div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="message-wrapper">
        <transition name="fade">
          <div v-if="message" class="message">
            <span class="check-icon"></span> {{ message }}
          </div>
        </transition>
      </div>
      <div class="modal-result">
        <transition-group class="module-grid" name="fade" tag="div">
          <div v-for="module in displayedMarkedModules" :key="module.id" class="module">
            <div class="module-content">
              <div class="left-content">
                <div class="module-detail">{{ module.mock ? '' : module.number }}</div>
                <div class="module-name">{{ module.name }}</div>
                <div class="module-detail">{{
                    module.mock && this.oneModulePerMockName ? '' : `${module.ects} ECTS`
                  }}
                </div>
              </div>
              <div class="right-content">
                <div class="right-control">
                  <div class="icon-container tooltip-container">
                    <span class="material-symbols-outlined" @click="unmarkModule(module)">{{ iconRemove }}</span>
                    <div class="tooltip-text bottom">{{ textRemove }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
      <div v-if="textHint.length !== 0" class="text-hint">
        <strong>Note:&ensp;</strong>{{ textHint }}
      </div>
    </template>
  </base-modal>
</template>

<script>
import BaseModal from "@/components/modals/BaseModal.vue";
import {mapState} from "vuex";

export default {
  name: "BaseModuleModal",
  components: {BaseModal},
  props: {
    modalName: String,
    moduleType: {
      type: String,
      default: ""
    },
    iconModal: {
      type: String,
    },
    modalTitle: {
      type: String,
    },
    modalSubtitle: {
      type: String,
      default: ""
    },
    textDiscard: {
      type: String,
      default: "Discard changes"
    },
    textConfirm: {
      type: String,
      default: ""
    },
    unavailableReason: {
      type: String,
      default: "inactive"
    },
    iconRemove: {
      type: String,
      default: "delete_outline"
    },
    textRemove: {
      type: String,
      default: "Remove"
    },
    textHint: {
      type: String,
      default: ""
    },
    unavailableModules: {
      type: Array,
      default: () => []
    },
    hiddenModules: {
      type: Array,
      default: () => []
    },
    addedModules: {
      type: Array,
      default: () => []
    },
    showInput: {
      type: Boolean,
      default: true
    },
    closingOffered: {
      type: Boolean,
      default: true
    },
    confirmationAlwaysEnabled: {
      type: Boolean,
      default: false
    },
    closeOnConfirm: {
      type: Boolean,
      default: true
    },
    discardingOffered: {
      type: Boolean,
      default: true
    },
    oneModulePerMockName: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return this.initialData();
  },
  computed: {
    ...mapState(["program", "preferences", "currentModal"]),
    baseTextConfirm() {
      if (this.$props.textConfirm.length !== 0) {
        return this.$props.textConfirm;
      }
      if (this.$props.confirmationAlwaysEnabled && this.markedModules.length === 0) {
        return `Continue without ${this.$props.moduleType ? this.$props.moduleType + ' ' : ''}modules`;
      }
      return "Confirm selection";
    },
    selectableModules() {
      let uniqueMockModules = new Set();
      const markedModuleNames = new Set(
          this.markedModules.map(id => {
            const foundModule = this.program.modules.find(module => module.id === id);
            return foundModule ? foundModule.name : null;
          })
      );

      let programModules = this.program.blocks.map(block => block.modules).flat();

      this.program.structures.forEach(structure => {
        if (structure.exclusive) {
          // Handle exclusive structure: Only include modules from the selected element
          const selectedElementId = this.preferences.structures[structure.id];
          const selectedElement = structure.elements.find(element => element.id === selectedElementId);
          if (selectedElement) {
            selectedElement.blocks.forEach(block => {
              programModules = [...programModules, ...block.modules];
            });
          }
        } else {
          // Handle non-exclusive structure: Include modules from all elements
          structure.elements.forEach(element => {
            element.blocks.forEach(block => {
              programModules = [...programModules, ...block.modules];
            });
          });
        }
      });

      return this.program.modules.filter(module => {
        // Exclude hidden and marked modules
        if (this.$props.hiddenModules.includes(module.id) || this.markedModules.includes(module.id)) {
          return false;
        }

        // Exclude modules that are not part of program with selected structures
        if (!programModules.includes(module.id)) {
          return false;
        }

        // Handle mock modules if only one per name can be added
        if (module.mock && this.oneModulePerMockName && markedModuleNames.has(module.name)) {
          return false;
        }

        // Handle mock modules if more than one per name can be added
        if (module.mock) {
          const uniqueKey = this.oneModulePerMockName ? module.name : `${module.name}_${module.ects}`;
          if (uniqueMockModules.has(uniqueKey)) {
            return false;
          }
          uniqueMockModules.add(uniqueKey);
        }

        return true;
      }).sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
    },
    modulesWithStructures(){
      const moduleStructuresMap = new Map();
      // Get the selected elements from selectedStructures
      const selectedElements = this.selectedStructures;
      selectedElements.forEach(selectedElement => {
        // Find the structure that contains this element
        const structure = this.program.structures.find(structure =>
          structure.elements.some(element => element.id === selectedElement.id)
        );
        if (structure) {
          selectedElement.blocks.forEach(block => {
            let modules = [...block.modules];
            if (block.blocks && block.blocks.length > 0) {
              modules = modules.concat(block.blocks.flatMap(blockId => this.findModules(blockId)));
            }
            modules.forEach(moduleId => {
              if (!moduleStructuresMap.has(moduleId)) {
                moduleStructuresMap.set(moduleId, {
                  elementId: selectedElement.id, 
                  structureId: structure.id,
                  structureColor: structure.highlight, 
                });
              }
            });
          });
        }
      });
      return Array.from(moduleStructuresMap.entries()).map(([moduleId, structure]) => ({
        moduleId,
        structure,
      }));
    },
    selectedStructures() {
      let selectedStructures = [];
      this.program.structures.forEach(structure => {
      const selectedElementId = this.preferences.structures[structure.id];
      if (selectedElementId) {
        const selectedElement = structure.elements.find(element => element.id === selectedElementId);
        if (selectedElement) {
          selectedStructures.push(selectedElement);
        }
      }
    });

      return selectedStructures;
    },
    displayedMarkedModules() {
      let markedModules = this.program.modules.filter(module => this.markedModules.includes(module.id));

      if (!this.oneModulePerMockName) {
        return markedModules;
      }

      // Create a set of module names from addedModules that are not in markedModules
      const excludedModuleNames = new Set(
          this.program.modules
              .filter(module => this.addedModules.includes(module.id) && !this.markedModules.includes(module.id))
              .map(module => module.name)
      );

      // Only return modules that are unique in their name, but are not removed from marked while Modal has been open
      const uniqueModuleNames = new Set();
      return markedModules.filter(module => {
        const isUnique = !uniqueModuleNames.has(module.name);
        const isNotExcluded = !excludedModuleNames.has(module.name);

        if (isUnique && isNotExcluded) {
          uniqueModuleNames.add(module.name);
          return true;
        }

        return false;
      });
    },
    changeDetected() {
      if (this.$props.addedModules.length !== this.markedModules.length) {
        return true;
      }

      let countMap = {};

      // Count the occurrences of each string in the first array
      for (let i = 0; i < this.$props.addedModules.length; i++) {
        countMap[this.$props.addedModules[i]] = (countMap[this.$props.addedModules[i]] || 0) + 1;
      }

      // Subtract the count for each string in the second array
      for (let i = 0; i < this.markedModules.length; i++) {
        if (!countMap[this.markedModules[i]]) {
          return true; // string found in this.markedModules that's not in this.$props.addedModules
        }
        countMap[this.markedModules[i]]--;
      }

      // Check if all counts are back to zero
      for (let key in countMap) {
        if (countMap[key] !== 0) {
          return true;
        }
      }

      return false;
    },
    confirmationDisabled() {
      return !(this.$props.confirmationAlwaysEnabled || this.changeDetected);
    },
  },
  watch: {
    currentModal(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.cleanModal();
      }
    }
  },
  methods: {
    initialData() {
      return {
        moduleInput: "",
        showDropdown: false,
        markedModules: this.$props.addedModules ? [...this.$props.addedModules] : [],
        message: '',
        fadeOut: false
      };
    },
    clickMarkModule(event, module) {
      if (event.button === 0 && !this.unavailableModules.includes(module.id)) { 
        this.markModule(module);
        this.message = `Module added`;
        this.fadeOut = false;

        setTimeout(() => {
          this.fadeOut = true;
          setTimeout(() => {
            this.message = '';
          }, 0);
        }, 500);
        this.moduleInput = "";
        this.$refs.input.blur();
        this.showDropdown = false;
      }
    },
    markModule(module) {
      if (!this.markedModules.includes(module.id)) {
        this.markedModules.push(module.id);
      }

      // If there are submodules, and they are not already selected, add them to the addedModules list
      if (module.submodules.length > 0) {
        module.submodules.forEach(submodule => {
          if (!this.markedModules.includes(submodule)) {
            this.markedModules.push(submodule);
          }
        });
      }

      // mark parent module if min number of marked submodules fulfilled
      if (module.submodule) {
        // find parent module
        const parentModule = this.program.modules.find(module => module.submodules.includes(module.id))
        if (!parentModule) return;
        // check if condition is fulfilled
        const numChildrenSelected = this.markedModules.filter(item => parentModule.submodules.includes(item)).length;
        if (!this.markedModules.includes(parentModule.id) && numChildrenSelected >= parentModule.numRequiredSubmodules) {
          this.markedModules.push(parentModule.id);
        }
      }

      // If there are dependent modules, also add them
      const dependees = this.getDependentModules(module)
      if (dependees) {
        dependees.forEach(dependee => {
          if (!this.markedModules.includes(dependee.id)) {
            this.markedModules.push(dependee.id);
          }
        });
      }
    },
    unmarkModule(module) {
      if (!this.markedModules.includes(module.id)) {
        return
      }
      this.markedModules = this.markedModules.filter(mod => mod !== module.id);
      // unmark parent module if condition not fulfilled anymore, because of possible earlier auto-addition
      if (module.submodule) {
        // find parent module
        const parentModule = this.program.modules.find(module => module.submodules.includes(module.id))
        if (!parentModule) return;
        // check if condition still fulfilled
        const numChildrenSelected = this.markedModules.filter(item => parentModule.submodules.includes(item)).length;
        if (this.markedModules.includes(parentModule.id) && numChildrenSelected < parentModule.numRequiredSubmodules) {
          // unmark parent if condition not fulfilled anymore
          this.markedModules = this.markedModules.filter(mod => mod !== parentModule.id);
        }
      }
    },
    getDependentModules(module) {
      return this.program.modules.filter(mod => mod.dependencies.includes(module.id));
    },
    filterModulesByInput(modules) {
      return modules.filter(module =>
          (module.id.toLowerCase().includes(this.moduleInput.toLowerCase())
              || module.nameDe.toLowerCase().includes(this.moduleInput.toLowerCase())
              || module.nameEn.toLowerCase().includes(this.moduleInput.toLowerCase())
          ));
    },
    findModules(blockId) {
      const block = this.program.blocks.find(b => b.id === blockId);
      if (!block) return [];

      let modules = [...block.modules];

      if (block.blocks && block.blocks.length > 0) {
        modules = modules.concat(block.blocks.flatMap(innerBlockId => this.findModules(innerBlockId)));
      }

      return modules;
    },
  
    structureColorClass(moduleId) {
     const moduleStructure = this.modulesWithStructures.find(item => item.moduleId === moduleId);
      if (moduleStructure) {
        let color = moduleStructure.structure.structureColor;
        if (moduleStructure.structure.structureId === "track") {
          color = `${color}_light`;
        }
        return `structure-color-${color}`;
      }
      return '';
    },
    reload() {
      this.$store.dispatch("requestSchedule");
    },
    confirm() {
      this.$emit("confirmOperation", this.markedModules);
      this.reload();
    },
    cleanModal() {
      Object.assign(this.$data, this.initialData());
    },
    toggleFocusInput() {
      this.showDropdown ? this.$refs.input.blur() : this.$refs.input.focus();
    }
  },
};
</script>

<style scoped>

.input-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.suggestion-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 0;
  margin: 0;
  max-height: 280px; /* 8 lines */
  overflow-y: auto;
  border: none;
  background-color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  z-index: 3;
  font-size: 90%;
}

.suggestion-dropdown li {
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
  cursor: pointer;
  border-bottom: 1px solid #f1f1f1;
  transition: background-color 0.2s ease; 
}

.suggestion-dropdown li.inactive {
  color: #ccc;
  cursor: inherit;
  background-color: #f9f9f9 !important;
}

.suggestion-dropdown li:not(.inactive):hover {
  background-color: #f1f1f1 !important; 
}

/* Background color classes */
.suggestion-dropdown li.structure-color-blue {
  background-color: lightblue;
}

.suggestion-dropdown li.structure-color-blue_light {
  background-color: lightblue;
}

.suggestion-dropdown li.structure-color-orange {
  background-color: #F9BF4E;
}

.suggestion-dropdown li.structure-color-orange_light {
  background-color: #FCE2B0;
}

.suggestion-dropdown li.structure-color-red {
  background-color: #FFCCCB;
}

.suggestion-dropdown li.structure-color-red_light {
  background-color: #FFCCCB;
}
.suggestion-dropdown .module-name-bar {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.list-details {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
}

.list-detail {
  color: #999999;
  font-size: 80%;
  text-align: right;
}

.list-detail-lang,
.list-detail-ects {
  width: 50px;
}

.inactive .list-detail {
  color: inherit;
}

.wide-input {
  width: 100%;
  padding: 10px 30px 10px 10px; /* right padding for expand_more icon */
  border: 1px solid #bbb;
  border-radius: 4px;
}

.icon-expand {
  position: absolute;
  right: 0;
  top: 0;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.icon-expand.dropdown-shown {
  transform: rotate(180deg);
}

.modal-result {
  flex-grow: 1;
  border: 1px dashed #bbb;
  border-radius: 4px;
  padding: 10px;
  overflow-y: auto;
}

.module-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  justify-content: center;
  font-size: 80%;
  line-height: 150%;
}

.module {
  min-width: 100px;
  max-width: 200px;
  height: 80px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px;
}

.icon-container {
  display: flex;
  align-items: center;
}

.material-symbols-outlined {
  font-size: 18px;
}

.module .material-symbols-outlined {
  cursor: pointer;
  color: #DDE2E6;
  transition: color 0.3s;
}

.module .material-symbols-outlined:hover {
  color: #6A757E;
}

.module-content {
  display: flex;
  height: 100%;
}

.left-content {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.module-detail {
  line-height: 1.5em;
  min-height: 1.5em;
}

.module-name {
  flex: 0 0 auto;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  margin: 5px 0;
}

.text-hint {
  flex-shrink: 0;
  flex-basis: auto;
  max-height: 3em;
  overflow-y: auto;

  font-size: 90%;
  line-height: 1.5em;
}
.message-wrapper {
  position: absolute;
  width: auto;
  display: flex;
  justify-content: center;
  pointer-events: none;
  z-index: 1000;
  transition: all 0.5s ease;
  bottom: calc(67% + 80px);
  left: 50%;
  transform: translateX(-50%);
}

.message {
  color: #000;
  background-color: #9ABCE4;
  border-radius: 8px;
  padding: 6px 16px;
  transform: translateY(180px);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  opacity: 1;
  transition: opacity 0.5s;
  pointer-events: auto;
  font-size: 12px;
  font-family: inherit;
  font-weight: bold;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}
.check-icon {
  width: 20px;
  height: 20px;
  display: inline-block;
  background-color: #28a745;
  mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="white" d="M13.78 3.72a.75.75 0 10-1.06-1.06L6.5 8.94l-2.22-2.22a.75.75 0 10-1.06 1.06l2.75 2.75c.3.3.77.3 1.06 0l6.75-6.75z"></path></svg>');
  -webkit-mask: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="white" d="M13.78 3.72a.75.75 0 10-1.06-1.06L6.5 8.94l-2.22-2.22a.75.75 0 10-1.06 1.06l2.75 2.75c.3.3.77.3 1.06 0l6.75-6.75z"></path></svg>') no-repeat center;
  mask-size: contain;
  -webkit-mask-size: contain;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  line-height: 24px;
  padding: 4px;
  font-size: 14px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

.fade-out {
  opacity: 0; /* Fade out effect */
}
</style>