<template>
  <base-module-modal
      :added-modules="preferences.undesiredModules"
      :hidden-modules="hiddenModules"
      :unavailable-modules="requiredModules"
      :modal-name="this.$options.name"
      :one-module-per-mock-name="true"
      @confirm-operation="updateUndesiredModules"
      icon-modal="cancel"
      modal-title="Manage undesired modules"
      unavailable-reason="required"
  >
  </base-module-modal>
</template>

<script>

import BaseModuleModal from "@/components/modals/BaseModuleModal.vue";
import {mapGetters, mapState} from "vuex";

export default {
  name: "UndesiredModulesModal",
  components: {BaseModuleModal},
  computed: {
    ...mapState(["preferences"]),
    ...mapGetters(["requiredModules"]),
    hiddenModules() {
      // do not offer passed modules as well as desired ones
      return this.preferences.passedModules
          .concat(Object.keys(this.preferences.desiredInSemModules))
          .concat(this.preferences.desiredModules)
    },
  },
  methods: {
    updateUndesiredModules(markedModules) {
      const modulesToRemove = this.preferences.undesiredModules.filter(module => !markedModules.includes(module))
      const modulesToAdd = markedModules.filter(module => !this.preferences.undesiredModules.includes(module))
      modulesToRemove.forEach(module => {
        this.$store.commit("REMOVE_UNDESIRED_MODULE", module);
      })
      modulesToAdd.forEach(module => {
        this.$store.commit("SAVE_STATE");
        this.$store.commit("ADD_UNDESIRED_MODULE", module);
      })
    }
  },
};
</script>
