<template>
  <div class="topbar">
    <div v-if="disclaimer.display" class="disclaimer">
      <span id="beta">{{ disclaimer.header }}</span>&ensp;&ensp;
      <span>{{ disclaimer.text }} <a v-if="disclaimer.displayLink"
          class="clickable-text" @click="openModal('ReportModal')">{{ disclaimer.linkText }}</a></span>
    </div>
    <div class="topbar-content">
      <div class="topbar-intro">
        <h1>
          <span>StudyPlanner</span>
        </h1>
        <h2 class="subtitle">
          <span>TUM School of Computation, Information and Technology</span>
        </h2>
      </div>
      <div class="topbar-tools">
        <div class="tool tooltip-container notification" @click="handleDownloadPDF">
          <div class="icon-container">
            <span class="material-symbols-outlined">print</span>
            <span class="material-symbols-outlined new-icon">fiber_new</span>
          </div>
          <div class="tooltip-text bottom">Print</div>
        </div>
        <div class="tool tooltip-container" @click="openModal('PrivacyModal')">
          <span class="material-symbols-outlined">admin_panel_settings</span>
          <div class="tooltip-text bottom">Privacy</div>
        </div>
        <div class="tool tooltip-container" @click="openModal('ImprintModal')">
          <span class="material-symbols-outlined">fingerprint</span>
          <div class="tooltip-text bottom">Imprint</div>
        </div>
        <div :class="{ 'highlight': showError }" class="tool tooltip-container" @click="openModal('ReportModal')">
          <span class="material-symbols-outlined">feedback</span>
          <div class="tooltip-text bottom">Give feedback or report a problem</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import {mapActions, mapGetters, mapState} from "vuex";
import {disclaimer} from "../../options.config";

export default {
  name: "TopBar",
  data() {
    return {
      isProduction: process.env.NODE_ENV === 'production'
    };
  },
  computed: {
    ...mapState(["hasSolution", "errorOccurred", "preferences", "schedule", "blockCredits"]),
    ...mapGetters(["reqPrefsSet", "prefChangeDisabled", "addedPassedModules"]),
    showError() {
      return this.errorOccurred ||
          (!this.prefChangeDisabled && this.reqPrefsSet && this.addedPassedModules && !this.hasSolution);
    },
    disclaimer() {
      return disclaimer;
    }
  },
  methods: {
    ...mapActions(["openModal"]),
    async handleDownloadPDF() {
      try {
      const preferences = this.$store.state.preferences;
      const schedule = this.$store.state.schedule;
      const blockCredits = this.$store.state.blockCredits;
      const response = await axios.post(process.env.VUE_APP_API_URL + "/api/pdf", {
          preferences, schedule, blockCredits,
      }, {
          responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'StudyPlan.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
      } catch (error) {
      console.error("Failed to download PDF:", error);
      }
    }
  }
};
</script>

<style scoped>
.topbar {
  width: 100vw;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 2; /* higher z-index to make sure shadow of topbar is visible */
}

.disclaimer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 12px;
  color: #fff;
  background-color: #D95117;
}

.disclaimer span {
  text-align: center;
  margin: 5px 0;
}

.clickable-text {
  cursor: pointer;
  text-decoration: underline;
}

#beta {
  font-size: 16px;
  font-weight: bold;
}

.topbar-content {
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topbar-intro {
  margin: 10px 20px;
}

.subtitle {
  color: #6A757E;
  font-weight: normal;
}

.topbar-tools {
  display: flex;
  margin: 10px 20px;
}

.tool {
  display: flex;
  justify-content: center;
  padding: 5px;
  margin: 5px;
  color: #6A757E;
  cursor: pointer;
  border-radius: 50%; /* Makes the background round */
  box-sizing: border-box;
  height: 34px;
  transition: color 0.2s, background-color 0.2s ease;
}

.icon-container {
  position: relative;
  display: inline-block;
}

.new-icon {
  position: absolute;
  top: -14px;
  right: -14px;
  font-size: 28px;
  color: #28a745;
}

.tool:hover {
  color: #20252A;
  background-color: rgba(68, 71, 70, 0.08);

}

.tool.highlight {
  color: #F3B295;
}

.tool.highlight:hover {
  color: #EA7237;
}
</style>
<script setup>
</script>