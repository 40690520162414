<template>
  <base-module-modal
      :added-modules="preferences.passedModules"
      :modal-name="this.$options.name"
      :closing-offered="addedPassedModules"
      :confirmation-always-enabled="!addedPassedModules"
      :discarding-offered="addedPassedModules"
      :text-hint="textHint"
      @confirm-operation="updatePassedModules"
      icon-modal="task_alt"
      :modal-subtitle="`Select all that you will have passed by the beginning of the ${currentSemester}.`"
      modal-title="Select passed modules (current degree only)"
  >
  </base-module-modal>
</template>

<script>

import BaseModuleModal from "@/components/modals/BaseModuleModal.vue";
import {mapGetters, mapState} from "vuex";

export default {
  name: "PassedModulesModal",
  components: {BaseModuleModal},
  computed: {
    ...mapGetters(["addedPassedModules"]),
    ...mapState(["preferences", "promptedPassedModules"]),
    textHint() {
      return this.preferences.degree === "msc" ? "If you cannot find a module, search for 'Special topic' (for " +
          "Mathematics) or 'Module' (for other Sciences) and select a module of the fitting area with the right " +
          "credit amount." : "";
    },
    currentSemester() {
      const semesterCode = this.preferences.currentSemester;
      if (!semesterCode) return ""; 
      const season = semesterCode.startsWith("w") ? "Winter Semester" : "Summer Semester";
      const year = `20${semesterCode.slice(1)}`; 
      return `${season} ${year}`;
    }
  },
  methods: {
    updatePassedModules(markedModules) {
      const modulesToRemove = this.preferences.passedModules.filter(module => !markedModules.includes(module))
      const modulesToAdd = markedModules.filter(module => !this.preferences.passedModules.includes(module))
      modulesToRemove.forEach(module => {
        this.$store.commit("REMOVE_PASSED_MODULE", module);
      })
      modulesToAdd.forEach(module => {
        this.$store.commit("ADD_PASSED_MODULE", module);
      })

      if (!this.promptedPassedModules) {
        /* Temporary hack: sets max semester ECTS to 36 to counter workload-ects-discrepancy */
        this.hackSemesterPreferences();
      }
      this.$store.commit("SET_PROMPTED_PASSED_MODULES");
      this.$store.commit("SAVE_STATE");
      
    },
    hackSemesterPreferences() {
      if (this.preferences.degree === "bsc" && this.preferences.major === "mathematics"
          && ["w24", "s25"].includes(this.preferences.startSemester)
          && !this.preferences.passedModules.includes("MA0006")
          && !this.preferences.passedModules.includes("MA0007")) {
        const semester = this.preferences.startSemester === "w24" ? 2 : 1;
        this.$store.commit("UPDATE_SEMESTER_PREFERENCE", {semester: semester, key: "default", value: false});
        this.$store.commit("UPDATE_SEMESTER_PREFERENCE", {semester: semester, key: "maxEcts", value: 36});
      }
    }
  },
};
</script>
